import React from 'react';
import CurrentDate from '../components/CurrentDate';

export default function TermsOfService() {
    return(
        <div>
            <h1>Terms of Service for Viveture</h1>
            <CurrentDate message="Last updated:" />

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing or using the Viveture mobile application and website (the "Service"), you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the Terms, you may not use our Service.</p>

            <h2>2. Description of Service</h2>
            <p>Viveture is a platform that connects pet owners with pet sitters. We do not provide pet sitting services directly.</p>

            <h2>3. User Accounts</h2>
            <ul>
                <li>You must create an account to use our Service. You are responsible for maintaining the confidentiality of your account information.</li>
                <li>You must provide accurate and complete information when creating your account.</li>
                <li>You are responsible for all activities that occur under your account.</li>
            </ul>

            <h2>4. User Conduct</h2>
            <p>You agree not to:</p>
            <ul>
                <li>Use the Service for any unlawful purpose</li>
                <li>Impersonate any person or entity</li>
                <li>Harass, abuse, or harm another person</li>
                <li>Interfere with or disrupt the Service</li>
            </ul>

            <h2>5. Pet Owner Responsibilities</h2>
            <p>As a pet owner, you are responsible for:</p>
            <ul>
                <li>Providing accurate information about your pets</li>
                <li>Ensuring your pets are properly vaccinated and in good health</li>
                <li>Providing necessary supplies and instructions for pet care</li>
            </ul>

            <h2>6. Pet Sitter Responsibilities</h2>
            <p>As a pet sitter, you are responsible for:</p>
            <ul>
                <li>Providing accurate information about your qualifications and experience</li>
                <li>Providing safe and appropriate care for pets</li>
                <li>Following all instructions provided by pet owners</li>
            </ul>

            <h2>7. Payments</h2>
            <ul>
                <li>Pet owners agree to pay the agreed-upon rate for services.</li>
                <li>Viveture charges a subscription fee for use of the Service.</li>
                <li>Pet sitters receive 100% of the payment for their services.</li>
            </ul>

            <h2>8. Liability</h2>
            <p>Viveture is not responsible for the actions of pet owners or pet sitters. We do not guarantee the quality or safety of pet sitting services.</p>

            <h2>9. Termination</h2>
            <p>We may terminate or suspend your account at our sole discretion, without notice, for any reason.</p>

            <h2>10. Changes to Terms</h2>
            <p>We reserve the right to modify these Terms at any time. We will notify users of any significant changes.</p>

            <h2>11. Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law provisions.</p>

            <h2>12. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at: support@viveture.com</p>
        </div>
    )
}
