import React, { useState } from 'react';
import SEO from '../components/SEO';
import './Contact.css';
import SuccessNotice from '../components/SuccessNotice';
import { sendContactUs } from '../Api/BaseController';
import emailjs from '@emailjs/browser';

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const OUTLOOK_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_OUTLOOK_TEMPLATE_ID;

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    honeyBotPot: ''
  });
  const [showSuccessNotice, setShowSuccessNotice] = useState(false);

  const areRequiredFieldsFilled = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      formData.email.trim() !== ''
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check if honeypot field is filled
    if (formData.honeyBotPot.trim() !== '') {
      console.log('Honeypot field filled. Likely spam.');
      return;
    }

    if (areRequiredFieldsFilled()) {
      console.log('Form submitted:', formData);

      const data = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        message: formData.message
      }

      await emailjs.send(SERVICE_ID, OUTLOOK_TEMPLATE_ID, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        zipCode: '',
        city: '',
        state: '',
        userType: '',
        latitude: '',
        longitude: '',
        message: formData.message
      }).then((result) => {
        console.log('Form data sent to Outlook:', result.text);
      }, (error) => {
        console.log('Failed to send data to Outlook:', error.text);
      });

      // await sendContactUs(data);
      // Clear form fields after submission
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
      });
      setShowSuccessNotice(true);

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);

      // show success notice
      setTimeout(() => {
        setShowSuccessNotice(false);
      }, 5000);
    }
  };

  return (
    <div className="contact-container">
      <SEO
        title="Contact Viveture - Get in Touch With Us"
        description="Have questions or need support? Contact the Viveture team. We're here to help with all your pet care needs."
        canonical="/contact"
      />
      <div className="contact-content">
        {showSuccessNotice && (
          <SuccessNotice
            message="Thank you. Your message has been received. We'll be in touch soon."
          />
        )}
        <h1>Contact Us</h1>
        <p>We're here to help! If you have any questions, concerns, or feedback, please don't hesitate to get in touch with us.</p>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                onChange={handleChange}
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                required
              />
            </div>
            {/* Prevent Bot Submission */}
            <div style={{ display: "none" }}>
              <input type="text" name="honeyBotPot" value={formData.honeyBotPot} onChange={handleChange} tabIndex="-1" />
            </div>
            {/* Prevent Bot Submission */}
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                onChange={handleChange}
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              onChange={handleChange}
              value={formData.email}
              type="email"
              id="email"
              name="email" required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              onChange={handleChange}
              id="message"
              name="message"
              value={formData.message}>
            </textarea>
          </div>
          <button
            type="submit"
            disabled={!areRequiredFieldsFilled()}
            className="cta-button-contact">
            Send Message
          </button>
        </form>
        <div className="contact-info">
          <h2>Other Ways to Reach Us</h2>
          <p>Email: support@viveture.com</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;