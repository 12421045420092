import React from 'react';
import CurrentDate from '../components/CurrentDate';

export default function Privacy() {
    return(
        <div>
            <h1>Privacy Policy for Viveture</h1>
            <CurrentDate
                message="Last Updated: "
            />
            <h2>1. Introduction</h2>
            <p>Viveture ("we", "our", or "us") is committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Viveture mobile application and website (collectively, the "Service").</p>

            <h2>2. Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
                <li>Personal Information: name, email address, phone number, and home address.</li>
                <li>Pet Information: basic details about your pets, including names and breeds.</li>
                <li>Location Data: we collect location data to connect pet owners with nearby sitters.</li>
                <li>Usage Data: information on how you use the Service, including log data and device information.</li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <p>We use your information to:</p>
            <ul>
                <li>Provide and maintain our Service</li>
                <li>Match pet owners with suitable sitters</li>
                <li>Communicate with you about our Service</li>
                <li>Improve and personalize our Service</li>
                <li>Ensure the safety and security of pets and users</li>
            </ul>

            <h2>4. Sharing of Your Information</h2>
            <p>We may share your information with:</p>
            <ul>
                <li>Pet sitters, as necessary to provide the service</li>
                <li>Service providers who assist in operating our business</li>
                <li>Law enforcement or other authorities if required by law</li>
            </ul>
            <p>We do not sell your personal information to third parties.</p>

            <h2>5. Data Security</h2>
            <p>We implement appropriate technical and organizational measures to protect your information. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>

            <h2>6. Your Rights</h2>
            <p>Depending on your location, you may have the right to:</p>
            <ul>
                <li>Access the personal information we hold about you</li>
                <li>Correct any inaccurate information</li>
                <li>Delete your personal information</li>
                <li>Object to or restrict the processing of your information</li>
            </ul>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

            <h2>8. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at: support@viveture.com</p>
        </div>
    )
};
