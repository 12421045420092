import React, { useState } from 'react';
import axios from 'axios';
import './SignUp.css';
import SuccessNotice from '../components/SuccessNotice';
import { sendEaryUserSignup } from '../Api/BaseController';
import emailjs from '@emailjs/browser';

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EARLYUSER_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_EARLYSIGNUP_TEMPLATE_ID;
const OUTLOOK_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_OUTLOOK_TEMPLATE_ID;

function Signup() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    zipCode: '',
    city: '',
    state: '',
    latitude: '',
    longitude: '',
    userType: '',
    honeyBotPot: ''
  });
  const [zipCodeInput, setZipCodeInput] = useState('');
  const [zipCodeSuggestions, setZipCodeSuggestions] = useState([]);
  const [showSuccessNotice, setShowSuccessNotice] = useState(false);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      // If the clicked checkbox is already selected, uncheck it
      if (formData.userType === value) {
        setFormData({ ...formData, userType: '' });
      } else {
        // Otherwise, select the clicked checkbox
        setFormData({ ...formData, userType: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleZipCodeChange = async (e) => {
    const input = e.target.value;
    setZipCodeInput(input);
    setFormData({ ...formData, zipCode: input, city: '', state: '' }); // Clear city and state when zip changes

    if (input.length === 5) {
      try {
        const response = await axios.get(`https://api.zippopotam.us/us/${input}`);
        if (response.data && response.data.places) {
          setZipCodeSuggestions(response.data.places.map(place => ({
            ...place,
            fullZipCode: response.data['post code']
          })));
        }
      } catch (error) {
        console.error('Error fetching zip code data:', error);
        setZipCodeSuggestions([]);
      }
    } else {
      setZipCodeSuggestions([]);
    }
  };

  const handleZipCodeSelect = (place) => {
    setFormData({
      ...formData,
      zipCode: place.fullZipCode,
      city: place['place name'],
      state: place['state abbreviation'],
      latitude: place.latitude,
      longitude: place.longitude
    });
    setZipCodeInput(place.fullZipCode);
    setZipCodeSuggestions([]);
  };

  const areRequiredFieldsFilled = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.zipCode.trim() !== '' &&
      formData.city.trim() !== '' &&
      formData.state.trim() &&
      formData.userType.trim() !== ''
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check for honeyBotPot field
    if (formData.honeyBotPot !== '') {
      console.log('HoneyBotPot field is filled. This is a bot.');
      return;
    }

    if (areRequiredFieldsFilled()) {
      const userTypes = {
        petOwner: 'Pet Owner',
        petSitter: 'Pet Sitter',
        both: 'Both'
      }

      // to use it when calling backend API
      const data = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        zip_code: formData.zipCode,
        city: formData.city,
        state: formData.state,
        latitude: formData.latitude,
        longitude: formData.longitude,
        user_type: userTypes[formData.userType]
      };
      // to use it when calling backend API

      await emailjs.send(SERVICE_ID, EARLYUSER_TEMPLATE_ID, {
        name: formData.firstName,
        recipient: formData.email,
      })
      .then(
        () => {
          console.log("Sent to user successfully!");
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      await emailjs.send(SERVICE_ID, OUTLOOK_TEMPLATE_ID, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        zipCode: formData.zipCode,
        city: formData.city,
        state: formData.state,
        userType: formData.userType,
        latitude: formData.latitude,
        longitude: formData.longitude,
        message: "Early User Signup Form"
      }).then((result) => {
        console.log('Form data sent to Outlook:', result.text);
      }, (error) => {
        console.log('Failed to send data to Outlook:', error.text);
      });

      // await sendEaryUserSignup(data);
      // Show success notice
      setShowSuccessNotice(true);

      // Clear the form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        zipCode: '',
        city: '',
        state: '',
        latitude: '',
        longitude: '',
        userType: ''
      });
      setZipCodeInput('');
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);

      // Hide the success notice after 5 seconds
      setTimeout(() => {
        setShowSuccessNotice(false);
      }, 5000);
    } else {
      console.log('Please fill all required fields');
      // You might want to show an error message to the user here
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up for Viveture</h2>
      {showSuccessNotice && (
        <SuccessNotice
          message="Thank you for signing up! We'll be in touch soon."
        />
      )}
      <form onSubmit={handleSubmit} className="signup-form">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="zipCode">Zip Code</label>
            <input
              type="number"
              id="zipCode"
              name="zipCode"
              value={zipCodeInput}
              onChange={handleZipCodeChange}
              maxLength="5"
              required
            />
            {zipCodeSuggestions.length > 0 && (
              <ul className="zipcode-suggestions">
                {zipCodeSuggestions.map((place, index) => (
                  <li key={index} onClick={() => handleZipCodeSelect(place)}>
                    {place.fullZipCode} - {place['place name']}, {place['state abbreviation']}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <p className="zipcode-instruction">Enter zip code and we will auto populate the city and state name.</p>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="state">State</label>
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
              readOnly
            />
          </div>
        </div>
        {/* Prevent Bot Submission */}
        <div style={{ display: "none" }}>
          <input type="text" name="honeyBotPot" value={formData.honeyBotPot} onChange={handleChange} tabIndex="-1" />
        </div>
        {/* Prevent Bot Submission */}
        <div className="checkbox-group">
          <p>Sign up as:</p>
          <div className="checkbox-row">
            <label>
              <input
                type="checkbox"
                name="userType"
                value="petOwner"
                checked={formData.userType === 'petOwner'}
                onChange={handleChange}
              /> Pet Owner
            </label>
            <label>
              <input
                type="checkbox"
                name="userType"
                value="petSitter"
                checked={formData.userType === 'petSitter'}
                onChange={handleChange}
              /> Pet Sitter
            </label>
            <label>
              <input
                type="checkbox"
                name="userType"
                value="both"
                checked={formData.userType === 'both'}
                onChange={handleChange}
              /> Both
            </label>
          </div>
        </div>
        <button type="submit" className="signup-button" disabled={!areRequiredFieldsFilled()}>Sign Up</button>
      </form>
    </div>
  );
}

export default Signup;