import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { PiPawPrintFill } from "react-icons/pi";
import './Header.css';

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">
          viveture
          <PiPawPrintFill className="logo-icon" />
        </Link>
        <nav className={`nav ${isNavOpen ? 'nav-open' : ''}`}>
          <ul>
            <li><Link to="/" onClick={toggleNav}>Home</Link></li>
            <li><Link to="/about" onClick={toggleNav}>About</Link></li>
            <li><Link to="/faq" onClick={toggleNav}>FAQ</Link></li>
            <li><Link to="/contact" onClick={toggleNav}>Contact</Link></li>
          </ul>
        </nav>
        <div className="nav-toggle" onClick={toggleNav}>
          {isNavOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </header>
  );
}

export default Header;