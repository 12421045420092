import React from 'react';
import './SuccessNotice.css';

export default function SuccessNotice({message}) {
  return (
    <div className="success-notice">
      <p>{message}</p>
    </div>
  );
};
