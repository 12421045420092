import React from 'react';

function CurrentDate({message}) {
  const today = new Date();
  const dateString = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div>
      <p>{message} {dateString}</p>
    </div>
  );
}

export default CurrentDate;
