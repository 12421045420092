import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaAppStoreIos } from 'react-icons/fa';
import { FaGooglePlay } from "react-icons/fa";
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Viveture</h3>
          <p>Pet Care Made Transparent</p>
        </div>
        <div className="footer-section">
          <h3>Connect With Us (WIP)</h3>
          <div className="social-icons">
            <a className="disabled" href="#" aria-label="Facebook"><FaFacebookF /></a>
            <a className="disabled" href="#" aria-label="Twitter"><FaTwitter /></a>
            <a className="disabled" href="#" aria-label="Instagram"><FaInstagram /></a>
            <a className="disabled" href="#" aria-label="LinkedIn"><FaLinkedinIn /></a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Coming Soon</h3>
            <FaAppStoreIos className="app-store-badge-footer" />
            <FaGooglePlay className="google-play-badge" />
        </div>
        <div className="footer-section">
          <h3>Be the first to be notified when we launch</h3>
          <Link to="/signup" className="footer-btn">Sign up for early access</Link>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Viveture. All rights reserved.</p>
        <p><Link to="/privacy">Privacy Policy</Link> | <Link to="/terms-of-service">Terms of Service</Link></p>
      </div>
    </footer>
  );
}

export default Footer;