import React from 'react';
import SEO from '../components/SEO';
import { Link } from 'react-router-dom';
import './About.css';
import founderImage from '../images/founder.JPG';
import buddyImage from '../images/buddy.png';

function About() {
  return (
    <div className="about">
      <SEO
        title="About Viveture - Revolutionizing Pet Care"
        description="Learn about Viveture, born from frustration with existing pet care apps. We offer simple, transparent, and fair services for both pet owners and sitters."
        canonical="/about"
      />
      <section className="about-hero">
        <h1>About Viveture</h1>
        <p className="tagline">Revolutionizing Pet Care with Simplicity and Transparency</p>
      </section>

      <section className="our-story">
        <h2>Our Story</h2>
        <div className="founder-info">
          <img src={founderImage} alt="Paresh Sharma, Founder and CEO of Viveture" className="founder-image" />
          <div>
            <p>I'm Paresh Sharma, founder and CEO of Viveture. As a long-time pet parent, I've used various pet care apps over the years and consistently found myself frustrated with the available options.</p>
            <p>Viveture was born out of this frustration and my dedication to providing simple, better, and most importantly, transparent pet care services. We believe that every pet deserves the best care, and every pet parent deserves peace of mind and fairness.</p>
          </div>
        </div>
        <div className="buddy-info">
          <img src={buddyImage} alt="Buddy, the inspiration behind Viveture" className="buddy-image" />
          <p>That adorable pup? That's Buddy! He's not just our mascot, but the inspiration behind Viveture. Just like Buddy, every pet needs better service, and just like me, every pet parent needs transparency and a platform they can trust and enjoy using.</p>
        </div>
      </section>

      <section className="for-sitters">
        <h2>For Sitters</h2>
        <p>At Viveture, we prioritize our sitters. We believe in fair compensation for the important work you do. Our model is straightforward: pay a simple subscription fee, and keep 100% of your earnings. No commissions, no hidden costs—just fair compensation for your important work</p>
        <p>We're not just another pet care app - we're a community that values your skills and dedication. With Viveture, you can focus on what you love - caring for pets - without worrying about losing a chunk of your hard-earned money.</p>
        <Link to="/signup" className="cta-button-about">Join Viveture as a Sitter</Link>
      </section>

      <section className="our-mission">
        <h2>Our Mission</h2>
        <p>Viveture's mission is simple: to make pet care accessible, affordable, and transparent for everyone. We're committed to creating a platform where simplicity meets efficiency, where trust is paramount, and where both pet owners and sitters can thrive.</p>
      </section>
    </div>
  );
}

export default About;