import React from 'react';
import SEO from '../components/SEO';

function FAQ() {
  return (
    <div className="faq">
      <SEO
        title="Viveture FAQ - Your Pet Care Questions Answered"
        description="Find answers to frequently asked questions about Viveture, the subscription-based pet care app. Learn about our services, pricing, and how to get started."
        canonical="/faq"
      />
      <h1>Frequently Asked Questions</h1>
      <div className="faq-item">
        <h2>What services does Viveture offer?</h2>
        <p>Viveture connects pet owners with sitters who offer services including House Boarding, Sitting, Walking, Dog Wash, Wash + Walk, and Check-ins.</p>
      </div>
      <div className="faq-item">
        <h2>How much does Viveture cost?</h2>
        <p>Viveture operates on a subscription model. It costs $7.99 per month or $59.99($5/month) per year. There are no additional service fees or booking fees.</p>
      </div>
      <div className="faq-item">
        <h2>How do sitters get paid?</h2>
        <p>Clients pay sitters directly before or after the service. Sitters keep 100% of their earnings - we don't take any commission.</p>
      </div>
      <div className="faq-item">
        <h2>How do I become a sitter on Viveture?</h2>
        <p>To become a sitter, simply sign up on our platform and make sure to check on "Sitter Profile". We'll guide you through rest of the process of creating your profile and getting verified.</p>
      </div>
    </div>
  );
}

export default FAQ;